import axios from "../../utils/request";

//获取组织
export function getOrganization(data) {
  return axios({
    url: "/clodchainAM/web/dept/queryDeptAll",
    method: "get",
    params: data
  });
}

//添加组织

export function deptAddDept(data) {
  return axios({
    url: "/clodchainAM/web/dept/addDept",
    method: "post",
    ...data
  });
}
// 编辑组织
export function deptUpdateDept(data) {
  return axios({
    url: "/clodchainAM/web/dept/updateDept",
    method: "post",
    ...data
  });
}
// 删除组织
export function deptDelDept(data) {
  return axios({
    url: "/clodchainAM/web/dept/delDept",
    method: "get",
    ...data
  });
}
//获取组织
export function queryDeptAll(data) {
  return axios({
    url: "/clodchainAM/web/dept/queryDeptAll",
    method: "get",
    ...data
  });
}
// 查询组织信息
export function deptQueryDeptOne(data) {
  return axios({
    url: "/clodchainAM/web/dept/queryDeptOne",
    method: "get",
    ...data
  });
}
