<template>
  <div class="content">
    <el-row :span="24" class="organization">
      <el-col :span="8">
        <div>
          <el-button
            class="import"
            icon="el-icon-download"
            @click="handleAdd"
            :disabled="!parameters.deptId || parameters.deptType > 3"
            >添加</el-button
          >
          <el-button
            class="import"
            :disabled="!parameters.deptId"
            :loading="loading.delete"
            @click="deptDelDept"
            icon="el-icon-delete"
            >删除</el-button
          >
        </div>
        <div class="app-organization-tree">
          <div
            class="app-organization-tree-container"
            :class="[isTree ? 'isTreeOn' : '']"
          >
            <!-- <div class="org-title">海信商用冷链</div> -->
            <el-tree
              ref="tree"
              node-key="deptId"
              :default-expanded-keys="expandedKeys"
              :current-node-key="deptId"
              icon-class="el-icon-caret-left"
              :expand-on-click-node="false"
              :data="getTreeData"
              :props="defaultProps"
              :check-strictly="true"
              @node-click="nodeClick"
            >
              <span
                slot-scope="scope"
                class="app-organization-tree-item-content"
              >
                <span class="app-tree-block" />
                <span class="app-tree-item-label">{{
                  scope.data.deptName
                }}</span>
              </span>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="organizationMes">
        <el-form
          v-show="parameters.deptId"
          ref="form"
          :rules="rules"
          hide-required-asterisk
          :model="form"
        >
          <el-form-item label="组织名称" prop="deptName">
            <el-input v-model="form.deptName"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <div style="margin-bottom:10px;font-size:0">
              <el-select
                v-model="form.province"
                placeholder="选择省"
                style="width:144px"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              <el-select
                v-model="form.city"
                placeholder="选择市"
                style="width:144px;margin-left:4px"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              <el-select
                v-model="form.country"
                placeholder="选择区"
                style="width:144px;margin-left:4px"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              >
            </div>
            <div>
              <el-input v-model="form.street" placeholder="详细地址"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="form.contactUser"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remarks" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width:140px" class="import" @click="handleCancle"
              >取消</el-button
            >
            <el-button
              type="primary"
              style="width:140px"
              :loading="loading.editSave"
              @click="onSubmit"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  queryDeptAll,
  deptAddDept,
  deptDelDept,
  deptQueryDeptOne,
  deptUpdateDept
} from "@/api/systemAdmin/organization";
import provinces from "@/utils/district.json";
export default {
  data() {
    const parameters = {
      deptId: "",
      deptType: -1
    };
    return {
      provinces: provinces,
      deptId: "",
      expandedKeys: [],
      getTreeData: [],
      defaultProps: {
        children: "children",
        label: "deptName"
      },
      parameters: {
        ...parameters
      },
      form: {
        province: "",
        city: "",
        country: "",
        deptName: "",
        street: "",
        phone: "",
        email: "",
        remarks: "",
        contactUser: ""
      },
      cancleForm: {},
      formData: {},
      loading: {
        addSave: false,
        editSave: false,
        delete: false
      },
      rules: {
        deptName: [
          {
            required: true,
            message: "请输入组织名称",
            trigger: "blur"
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (
                !/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                  value
                ) &&
                value
              ) {
                callback(new Error("请输入正确的邮箱!"));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ]
      },
      type: "edit" //编辑(edit)和添加(add)状态
    };
  },
  created() {
    this.defaultFormVal = {
      ...this.form
    };
    this.queryDeptAll();
  },
  computed: {
    cityList() {
      const result = this.provinces.find(item => {
        return item.label === this.form.province;
      });
      return result ? result.children : [];
    },
    areaList() {
      const result = this.cityList.find(item => {
        return item.label === this.form.city;
      });
      return result ? result.children : [];
    }
  },
  watch: {
    getTreeData: {
      deep: true,
      handler(val = []) {
        // 是否有数据
        if (val.length > 0) {
          this.isTree = true;
        } else {
          this.isTree = false;
        }
        // 默认展开key
        this.expandedKeys = [];
        const keyList = [];
        function sw(data) {
          data.forEach(item => {
            if (item.children.length > 0) {
              keyList.push(item.deptId);
              sw(item.children);
            } else {
              return;
            }
          });
        }
        sw(val);
        this.expandedKeys = keyList;
      },
      immediate: true
    },
    "form.province": {
      handler() {
        const result = this.cityList.find(item => {
          return item.label === this.form.city;
        });
        if (!result) {
          this.form.city = "";
          this.form.country = "";
        }
      }
    },
    "form.city": {
      handler() {
        const result = this.areaList.find(item => {
          return item.label === this.form.country;
        });
        if (!result) {
          this.form.country = "";
        }
      }
    }
  },
  methods: {
    // 选中
    nodeClick(data) {
      this.type = "edit";
      this.parameters.deptId = data.deptId;
      this.parameters.deptType = data.deptType;
      const { parentsId, deptId } = data;
      this.formData = Object.assign(this.formData, { parentsId, deptId });
      this.deptQueryDeptOne();
    },
    // 取消
    handleCancle() {
      if (this.type === "add") {
        this.form = { ...this.defaultFormVal };
        return;
      }
      this.form = { ...this.cancleForm };
    },
    // 保存
    onSubmit() {
      this.loading.editSave = true;
      if (this.type === "add") {
        this.deptAddDept();
        return;
      }
      const config = {
        data: this.form
      };
      deptUpdateDept(config).then(({ data }) => {
        if (data.resultCode === 0) {
          this.deptQueryDeptOne();
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success"
          });
        } else {
          //
          this.$message({
            showClose: true,
            message: data.errorDesc,
            type: "error"
          });
        }
        this.loading.editSave = false;
      });
    },
    // 添加按钮
    handleAdd() {
      this.type = "add";
      this.form = { ...this.defaultFormVal };
    },
    // 添加组织
    deptAddDept() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const config = {
            data: {
              parentsId: this.formData.parentsId + `,${this.formData.deptId}`,
              parentId: this.formData.deptId,
              userId: localStorage.getItem("userId"),
              deptType: this.parameters.deptType + 1,
              ...this.form
            }
          };
          deptAddDept(config).then(({ data }) => {
            this.loading.editSave = false;
            this.queryDeptAll();
            if (data.resultCode === 0) {
              this.$message({
                showClose: true,
                message: "保存成功！",
                type: "success"
              });
            }
          });
        } else {
          this.loading.editSave = false;
          return false;
        }
      });
    },
    // 删除组织
    deptDelDept() {
      this.$alert("确定要删除选中的组织么？", "删除", {
        confirmButtonText: "确定",
        callback: action => {
          if (action === "confirm") {
            this.loading.delete = true;
            const config = {
              params: {
                deptId: this.parameters.deptId,
                userId: localStorage.getItem("userId")
              }
            };
            deptDelDept(config).then(({ data }) => {
              this.loading.delete = false;
              this.queryDeptAll();
              if (data.resultCode === 0) {
                this.$message({
                  showClose: true,
                  message: "删除成功！",
                  type: "success"
                });
              } else {
                this.$message({
                  showClose: true,
                  message: data.errorDesc || "删除失败！",
                  type: "error"
                });
              }
            });
          }
        }
      });
    },
    // 获取当前组织信息
    deptQueryDeptOne() {
      const config = {
        params: { deptId: this.parameters.deptId }
      };
      deptQueryDeptOne(config).then(({ data }) => {
        if (data.resultCode === 0) {
          this.form = Object.assign(this.form, data.data);
          this.cancleForm = { ...this.form };
        }
      });
    },
    // 获取所有组织
    queryDeptAll() {
      this.deptId = "";
      this.parameters.deptId = "";
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = [...data.data];
        arr.forEach(el => {
          el.children = [];
          arr.forEach(value => {
            if (el.deptId === value.parentId) {
              el.children.push(value);
            }
          });
        });
        const item = arr.find(
          el => el.deptId === Number(localStorage.getItem("deptId"))
        );
        this.getTreeData = [item];
      });
    }
  }
};
</script>

<style lang="scss">
.content {
  .organization {
    padding: 0 30px;
    .app-organization-tree-container.isTreeOn {
      padding-left: 6px;
      border-left: 1px solid #ebebeb;
      .el-tree {
        padding-left: 36px;
        position: relative;
        .el-tree-node {
          padding-left: 20px;
          position: relative;
          &[aria-expanded*="true"]:after {
            content: "";
            display: block;
            position: absolute;
            border-left: 1px solid #ebebeb;
            top: 24px;
            bottom: 17px;
            left: 26px;
          }
        }
        & > .el-tree-node {
          &[aria-expanded*="true"]:after {
            content: "";
            display: block;
            position: absolute;
            border-left: 1px solid #ebebeb;
            top: 24px;
            bottom: 17px;
            left: 6px;
          }
          padding-left: 0;
          .el-tree-node {
            &:before {
              content: "";
              position: absolute;
              display: block;
              border-top: 1px solid #ebebeb;
              width: 13px;
              left: 7px;
              top: 17px;
            }
          }
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          border-top: 1px solid #ebebeb;
          width: 43px;
          top: 16px;
          left: -7px;
        }
      }
    }
    .app-organization-tree {
      float: left;
      width: 400px;
      margin-right: 20px;
      margin-top: 20px;
      .app-rule-label {
        display: block;
        font-weight: normal;
        font-size: 14px;
        color: #858585;
        margin-bottom: 10px;
      }

      .el-tree-node__content {
        // padding-left: 0 !important;
        height: 35px;
        /* height:14px;
    margin-bottom:21px; */
        background-color: transparent !important;
        /* &:hover{
      background-color:transparent;
    } */
        .el-tree-node__expand-icon {
          order: 1;
          color: #7c7c7c;
          font-size: 15px;
          &.is-leaf {
            color: transparent;
          }
          &.expanded {
            transform: rotate(-90deg);
          }
        }
        .app-organization-tree-item-content {
          font-size: 14px;
          color: #333;
          order: 0;
          display: flex;
          align-items: center;
          .app-tree-block {
            width: 12px;
            height: 12px;
            background: #00cccc;
            margin-right: 3px;
          }
          .app-tree-item-label {
            padding: 4px;
            border-radius: 3px;
            transition: 0.1s;
          }
        }
      }
      .is-current > .el-tree-node__content {
        .app-tree-item-label {
          background: #00cccc;
          color: #fff;
        }
      }

      .el-checkbox__inner {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        transform: translateY(1px);
      }
      .el-checkbox__inner::after {
        width: 5px;
        height: 8px;
      }
      .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
        top: 6px;
      }
    }
    .organizationMes {
      position: relative;
      z-index: 20;
      background: #fff;
    }
  }
}
</style>
